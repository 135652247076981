:global(#app) {
  height: 100%;
}

.releaseInProgress {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: 50% 50%;
  flex-direction: column;

  .logo {
    height: 34px;
    margin-bottom: 40px;
  }

  .animation {
    width: 232px;
    border-radius: $borderRadiusMiddle;
  }

  .message {
    max-width: 540px;
    padding: 40px 0;

    h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: rgb($text01, 1);
      text-align: center;
      margin-bottom: 16px;
    }

    .description {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 16px;
    }

    .hint {
      font-size: 14px;
      line-height: 24px;
      text-align: center;
    }
  }
}
